import { gql } from '@clientos/graphql-client';

const GET_PAYMENT_METHODS = gql`
  query Query {
    account {
      subscriptions {
        resourceId
        friendlyId
        type
        state
        billing {
          paymentMethod {
            ... on CreditCard {
              status
              type
              cardType
              lastFour
              expirationDate
            }
            ... on PayPal {
              status
              type
              email
            }
          }
        }
      }
    }
  }
`;

export default GET_PAYMENT_METHODS;
