import { useQuery } from '@clientos/graphql-client/dist/react';
import React, { useEffect, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  Loading,
  ApiSubscriptionStates,
  getSubscriptionCommercialState,
  CommercialSubscriptionStates,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import GET_PAYMENT_METHODS from 'src/graphql/getPaymentMethods';
import { Icon, IconGhost, NoDataText, Section } from 'src/styles/styles';
import {
  HpStoreWalletsButtonClicked,
  PaymentMethodsError,
  PaymentMethodsScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import PaymentContainer from '../../components/Payment';
import {
  GhostContent,
  PaymentContainerCard
} from '../../components/Payment/styles';
import { PaymentMethodsContainer } from './styles';
import { PaymentMethodStatus, PaymentMethodType } from 'src/utils/enums';

interface SubscriptionBilling {
  nextBillingCycle: {
    chargeDate: string;
    commonBillingDate: string;
  };
  paymentMethod: {
    type: PaymentMethodType;
    status: PaymentMethodStatus;
  };
}

const PaymentMethods = () => {
  const { translate } = useDependencyManagerContext();

  const htmlString = translate(
    'payments.findMoreCardsAndPayment',
    'Find more cards and payment methods in your {{tagReplaceStart}}HP Store wallet{{tagReplaceEnd}}.'
  );
  const parts = htmlString.split(/{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/);

  const { data, error, loading } = useQuery(GET_PAYMENT_METHODS);

  const instantInkPaymentMethod = useMemo(() => {
    return (
      data?.account?.subscriptions?.filter(
        (subscription: {
          type: SubscriptionType;
          state: ApiSubscriptionStates;
          resourceId: string;
          billing: { paymentMethod: SubscriptionBilling };
        }) =>
          subscription.type === SubscriptionType.INSTANT_INK &&
          subscription.billing?.paymentMethod &&
          getSubscriptionCommercialState(
            subscription.state,
            subscription.type
          ) !== CommercialSubscriptionStates.INACTIVE
      ) || []
    );
  }, [data?.account?.subscriptions]);

  const hpAllInPaymentMethod = useMemo(() => {
    return (
      data?.account?.subscriptions?.filter(
        (subscription: {
          type: SubscriptionType;
          state: ApiSubscriptionStates;
          friendlyId: string;
          billing: { paymentMethod: SubscriptionBilling };
        }) =>
          subscription.type === SubscriptionType.HP_ALL_IN &&
          subscription.billing?.paymentMethod &&
          getSubscriptionCommercialState(
            subscription.state,
            subscription.type
          ) !== CommercialSubscriptionStates.INACTIVE
      ) || []
    );
  }, [data?.account?.subscriptions]);

  const dataPayments = useMemo(
    () =>
      [...hpAllInPaymentMethod, ...instantInkPaymentMethod]?.filter((i) => i),
    [hpAllInPaymentMethod, instantInkPaymentMethod]
  );

  useEffect(() => {
    if (!loading && !error && dataPayments?.length > 0) {
      const message = `totalPaymentMethodCt=${dataPayments?.length}&totalInstanInkCt=${instantInkPaymentMethod?.length}&totalHpAllInCt=${hpAllInPaymentMethod?.length}`;
      publishEvent(PaymentMethodsScreenDisplayed(message));
    }
  }, [
    loading,
    error,
    dataPayments,
    instantInkPaymentMethod,
    hpAllInPaymentMethod
  ]);

  return (
    <div data-testid="PaymentMethods">
      {loading && (
        <Section
          data-testid="loading-payment-methods"
          appearance="outlined"
          content={<Loading />}
        />
      )}
      {error && (
        <ErrorBoundary analyticsEvent={PaymentMethodsError(error.message)} />
      )}
      {!loading && !error && dataPayments.length > 0 && (
        <PaymentMethodsContainer>
          {dataPayments?.map((payment, index) => (
            <PaymentContainer
              key={index}
              subscriptionId={
                payment?.type === SubscriptionType.HP_ALL_IN
                  ? payment?.friendlyId
                  : payment?.resourceId
              }
              type={payment?.type}
              billing={payment?.billing}
            />
          ))}
        </PaymentMethodsContainer>
      )}
      {!loading && !error && dataPayments.length <= 0 && (
        <PaymentMethodsContainer>
          <PaymentContainerCard
            data-testid="EmptyPaymentContainerCard"
            appearance="outlined"
            content={
              <GhostContent>
                <Icon>
                  <IconGhost size={40} />
                </Icon>
                <NoDataText data-testid="NoPaymentMethodsAddedYet">
                  {`${translate(
                    'payments.noPaymentMethodsAddedYet',
                    'No payment methods added yet!'
                  )}`}
                </NoDataText>
              </GhostContent>
            }
          />
        </PaymentMethodsContainer>
      )}
      <p className="body">
        {parts[0]}
        <a
          data-testid="HpStoreWalletsButton"
          href={URLS.HpStoreWallets}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => publishEvent(HpStoreWalletsButtonClicked)}
        >
          {parts[1]}
        </a>
        {parts[2]}
      </p>
    </div>
  );
};

export default PaymentMethods;
