import { Button } from '@veneer/core';
import React, { useEffect } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { publishEvent, AnalyticsEventPropTypes } from 'src/utils/analytics';
import {
  ErrorContent,
  ErrorTextContainer,
  ErrorTopWrapper,
  StyledIconTools
} from './styles';

const ErrorBoundary = ({
  analyticsEvent
}: {
  analyticsEvent: AnalyticsEventPropTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  useEffect(() => {
    publishEvent(analyticsEvent);
  }, [analyticsEvent]);
  const { translate } = useDependencyManagerContext();

  return (
    <ErrorContent>
      <ErrorTopWrapper>
        <StyledIconTools />
        <ErrorTextContainer>
          <div className="subtitle-large">
            {`${translate('common.itsNotYou', 'It’s not you, it’s us...')}`}
          </div>
          <div className="body">
            {`${translate(
              'common.thereWasAnInterruption',
              'There was an interruption while loading this information.'
            )}`}
          </div>
        </ErrorTextContainer>
      </ErrorTopWrapper>
      <Button
        appearance="secondary"
        onClick={() => {
          window.location.reload();
        }}
        data-testid="try-again-button"
      >
        {`${translate('common.tryAgain', 'Please try again')}`}
      </Button>
    </ErrorContent>
  );
};

export default ErrorBoundary;
