import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const PaymentFlagImage = styled('img')`
  height: ${tokens.layout.size7};
  width: 40px;
  margin-bottom: ${tokens.layout.size2};
`;

export const Expired = styled.p`
  color: ${tokens.color.red7};
`;

export const PayPalFlagImage = styled('img')`
  width: 68px;
  height: 16.52px;
  margin-bottom: ${tokens.layout.size2};
`;

export const HiperLink = styled.a`
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${tokens.color.hpBlue7};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size7};
  align-self: stretch;
  height: 160px;

  & > .div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.layout.size1};
    align-self: stretch;
  }

  & > #manage {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    & > p {
      padding-right: ${tokens.layout.size3};
    }

    & > button {
      color: ${tokens.color.hpBlue6};
    }
  }

  & > #paypal {
    margin-top: 20px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    > p {
      padding-right: ${tokens.layout.size3};
    }
  }
`;

export const GhostContent = styled.div`
  display: flex;
  width: 100%;
  height: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.layout.size2};
`;

export const PaymentContainerCard = styled(Card)`
  display: flex;
  padding: ${tokens.layout.size4} ${tokens.layout.size4} ${tokens.layout.size2};
  width: 292px;
`;
