import React from 'react';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import { IconPlusOpenAll, IconShoppingCart } from '@veneer/core/';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { HpStoreOrdersButtonClicked } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';

const MoreOptionsOrder = () => {
  const { translate } = useDependencyManagerContext();

  const rows = [
    {
      index: 0,
      icon: <IconShoppingCart />,
      type: ROW_TYPE.EXTERNAL,
      content: translate(
        'orders.lookYourPurchasesOrOrders',
        'Look for more of your purchases in your HP Store orders'
      ),
      link: {
        url: URLS.MyOrders,
        dataTestId: 'hp-store-orders'
      },
      analyticsEvent: HpStoreOrdersButtonClicked
    },
    {
      index: 1,
      icon: <IconPlusOpenAll />,
      type: ROW_TYPE.INTERNAL,
      content: translate(
        'orders.manageYourSubscriptions',
        'Manage your subscriptions in Subscriptions & Services'
      ),
      link: {
        pathname: '/subscriptions',
        dataTestId: 'subscriptions-link'
      }
    }
  ] as CardRowProps[];

  return (
    <Card
      title={{
        content: translate(
          'orders.dontSeeTheOrder',
          'Don’t see the order you’re looking for?'
        ) as string
      }}
      rows={rows}
      data-testid="more-options-order"
    />
  );
};

export default MoreOptionsOrder;
