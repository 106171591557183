import { Card, IconGhostWaveWall } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

const breakpoints = {
  mobile: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMin: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin // 1200px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    mobile: `(max-width: 375px)`,
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.xsMax}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.lgMin})`,
    large: `min-width: ${breakpoints.lgMin}`
  }
};

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
  margin-bottom: 20px;
  margin-top: ${tokens.layout.size5};
  max-width: 1140px;

  && {
    border-radius: ${tokens.layout.size4};
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: ${tokens.layout.size7};
  align-items: center;

  @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};

  &:last-child {
    margin-bottom: ${tokens.layout.size2};
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;

  @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const ErrorSection = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  gap: ${tokens.layout.size8};
`;

export const Gap8 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size2};
`;

export const NoDataText = styled.body`
  display: flex;
  justify-content: center;
  && {
    color: ${tokens.color.gray9};
  }
`;

export const IconGhost = styled(IconGhostWaveWall)`
  && {
    color: ${tokens.color.gray9};
  }
`;
