import React, { useCallback, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { useWebcomponent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import Orders from 'src/pages/Orders';
import PaymentMethods from 'src/pages/PaymentMethods';
import { PaymentMethodsButtonClicked, publishEvent } from 'src/utils/analytics';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { Container, Content, Header, VeneerTabs } from './styles';
import { TabsId } from '@veneer/core';

const OrdersAndPayments = ({ tab = 0 }: { tab?: number }): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(tab);
  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);
  const { translate } = useDependencyManagerContext();

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  const handleChangeTab = useCallback((tabId: number) => {
    setSelectedTab(tabId);
    // 0 = Orders , 1 = Payment methods
    if (tabId === 1) publishEvent(PaymentMethodsButtonClicked);
  }, []);

  return (
    <Container>
      <Header>
        <h4>{`${translate('menu.orders', 'Orders & payments')}`}</h4>
        <p className="body">
          {`${translate(
            'orders.review',
            'Review your current and past HP orders and manage payment methods'
          )}`}
        </p>
      </Header>
      <Content>
        <VeneerTabs
          controlId="default"
          onChangeTab={(tabId: TabsId) =>
            handleChangeTab(parseInt(tabId.toString()))
          }
          selectedTabId={selectedTab}
          data-testid="veneer-tabs"
          tabs={[
            {
              id: 0,
              label: translate('orders.ordersLabel', 'Orders'),
              content: <Orders orders={[]} />
            },
            {
              id: 1,
              label: translate('orders.paymentMethods', 'Payment methods'),
              content: <PaymentMethods />
            }
          ]}
          type="online"
        />
      </Content>
    </Container>
  );
};

export default OrdersAndPayments;
