import { Stack } from '@jarvis/web-stratus-client';

export const HP_SMART_PROD_URL = 'https://www.hpsmart.com/ucde';
export const HP_SMART_STAGE_URL =
  'https://consumer.stage.portalshell.int.hp.com';

export const QUALTRICS_SURVEY_PROD_URL =
  'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU';
export const QUALTRICS_SURVEY_TEST_URL =
  'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia';

export const getHpSmartUrl = (): string => {
  const stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === 3 ? HP_SMART_PROD_URL : HP_SMART_STAGE_URL;
};

export const getQualtricsSurveyUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod
    ? QUALTRICS_SURVEY_PROD_URL
    : QUALTRICS_SURVEY_TEST_URL;
};

export const URLS = {
  MyOrders:
    'https://www.hp.com/us-en/shop/MyOrdersView?catalogId=10051&langId=-1&storeId=10151',
  UnifiedShippingBillingURL: `${getHpSmartUrl()}/account-details/shipping-billing`,
  HpStoreWallets:
    'https://www.hp.com/us-en/shop/LogonForm?catalogId=10051&storeId=10151&langId=-1&krypto=QCyh75VZtlJEnR%2FWdAC8rppdEiWNt2%2FcRZncwU0lu9xrrdYgngeKxJaHSyNIFY4ZlzKJWwZYdXBqc8N3zFSwFKIL2MgXt552Mem9Wwa4i9Uln0YZ08%2BgArIqmnOWdWZJ0GxsDhlvM0PL6uIQMEfA8HxvzWXGtgIu3nA6ZSosPYAron%2BBCFvO8bDJ2lc2sXiH&ddkey=https%3AMySavedCards'
};
