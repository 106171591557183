import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const PaymentMethodsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: ${tokens.layout.size5};
  margin: ${tokens.layout.size5} 0px;

  @media (max-width: ${tokens.layout.smMax}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${tokens.layout.size6};
  }
`;

export const ContainerCard = styled(Card)`
  display: flex;
  padding: ${tokens.layout.size4};
  width: 324px;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    padding: ${tokens.layout.size4} ${tokens.layout.size4}
      ${tokens.layout.size2} ${tokens.layout.size4};
  }
`;
