import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import { URLS } from './urls';

const activity = 'UnifiedAcctMgmt-v01';
const eventDetailVersion = '1.5.0';
export const enum ScreenPath {
  ORDERS = '/ReactMyAccountOrders/',
  PAYMENT = '/ReactMyAccountOrders/OrdersAndPayment/'
}
export const enum ScreenName {
  ORDERS = 'OrdersAndPayment',
  PAYMENT = 'PaymentMethods'
}

export type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

// Orders
export const OrdersAndPaymentScreenDisplayed = (totalOrdersCt: string) => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath: ScreenPath.ORDERS,
    screenName: ScreenName.ORDERS,
    actionAuxParams: totalOrdersCt,
    version: eventDetailVersion
  };
};

export const HpStoreOrdersButtonClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: ScreenPath.ORDERS,
  screenName: ScreenName.ORDERS,
  controlName: 'HpStoreOrders',
  controlDetail: URLS.MyOrders,
  version: eventDetailVersion
};

export const PaymentMethodsButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: ScreenPath.ORDERS,
  screenName: ScreenName.ORDERS,
  controlName: 'PaymentMethods',
  version: eventDetailVersion
};

export const ManageHpInstantInkSubscriptionButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: ScreenPath.ORDERS,
  screenName: ScreenName.ORDERS,
  controlName: 'ManageHpInstantInkSubscription',
  version: eventDetailVersion
};

export const ManageHpAllInSubscriptionSubscriptionButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: ScreenPath.ORDERS,
  screenName: ScreenName.ORDERS,
  controlName: 'ManageHpAllInSubscription',
  version: eventDetailVersion
};

export const HpInstantInkSubscriptionDetailsButtonClicked = (
  timestamp: string,
  id: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: ScreenPath.ORDERS,
    screenName: ScreenName.ORDERS,
    controlName: 'HpInstantInkSubscriptionDetails',
    actionAuxParams: `subscriptionStartDate=${timestamp}&subscriptionId=${id}`,
    version: eventDetailVersion
  };
};

export const HpAllInSubscriptionDetailsButtonClicked = (
  timestamp: string,
  id: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: ScreenPath.ORDERS,
    screenName: ScreenName.ORDERS,
    controlName: 'HpAllInSubscriptionDetails',
    actionAuxParams: `subscriptionStartDate=${timestamp}&subscriptionId=${id}`,
    version: eventDetailVersion
  };
};

// Payment Methods
export const PaymentMethodsScreenDisplayed = (
  totalPaymentMethodCt: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath: ScreenPath.PAYMENT,
    screenName: ScreenName.PAYMENT,
    actionAuxParams: totalPaymentMethodCt,
    version: eventDetailVersion
  };
};

export const ManageHpInstantPaymentButtonClicked = (
  subscriptionId: string,
  url: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: ScreenPath.PAYMENT,
    screenName: ScreenName.PAYMENT,
    controlName: 'ManageHpInstantPayment',
    controlDetail: url,
    actionAuxParams: `subscriptionId=${subscriptionId}`,
    version: eventDetailVersion
  };
};

export const ManageHpAllInPaymentButtonClicked = (
  subscriptionId: string,
  url: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: ScreenPath.PAYMENT,
    screenName: ScreenName.PAYMENT,
    controlName: 'ManageHpAllInPayment',
    controlDetail: url,
    actionAuxParams: `subscriptionId=${subscriptionId}`,
    version: eventDetailVersion
  };
};

export const HpStoreWalletsButtonClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: ScreenPath.PAYMENT,
  screenName: ScreenName.PAYMENT,
  controlName: 'HpStoreWallets',
  controlDetail: URLS.HpStoreWallets.slice(0, 256),
  version: eventDetailVersion
};

export const PaymentMethodsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${ScreenPath.PAYMENT}PaymentMethods/`,
    screenName: 'PaymentMethodsError',
    version: eventDetailVersion
  };
};

export const OrdersError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${ScreenPath.ORDERS}OrdersAndPayment/`,
    screenName: 'OrdersError',
    version: eventDetailVersion
  };
};
