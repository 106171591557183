import { Button } from '@veneer/core';
import React, { useCallback, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import Images from 'src/assets/images/index';
import getExpirationStatus from 'src/helpers/getExpirationStatus';
import formatPaymentMethodFlag from 'src/helpers/getPaymentFlag';
import {
  ManageHpAllInPaymentButtonClicked,
  ManageHpInstantPaymentButtonClicked,
  publishEvent
} from 'src/utils/analytics';
import { creditCardMask } from 'src/utils/masks';
import { URLS } from 'src/utils/urls';
import {
  CardContainer,
  CardContent,
  Expired,
  PayPalFlagImage,
  PaymentContainerCard,
  PaymentFlagImage
} from './styles';

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED'
}

export enum PaymentMethodType {
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAY_PAL'
}

export interface IPayment {
  status?: PaymentMethodStatus;
  type: PaymentMethodType;
  cardType?: string;
  lastFour?: string;
  expirationDate?: string;
  accountName?: string;
  email?: string;
}

export interface ISubscription {
  type: string;
  subscriptionId: string;
  billing: {
    paymentMethod: IPayment;
  };
}

export default function PaymentContainer({
  type,
  subscriptionId,
  billing
}: ISubscription): JSX.Element {
  const { translate } = useDependencyManagerContext();

  const formatedPaymentFlag = formatPaymentMethodFlag(
    billing?.paymentMethod?.cardType
  );

  const formatedTypePaymentFlag = formatPaymentMethodFlag(
    billing?.paymentMethod?.type
  );

  const linkedText =
    type === 'HP_ALL_IN'
      ? translate('payments.linkedHpAllIn', 'Linked to your HP All-In Plan')
      : translate(
          'payments.linkedInstantInk',
          'Linked to your HP Instant Ink Plan'
        );

  const handleButtonClickCreditCard = useCallback(() => {
    const url = URLS.UnifiedShippingBillingURL;

    const analytics =
      type === 'HP_ALL_IN'
        ? ManageHpAllInPaymentButtonClicked
        : ManageHpInstantPaymentButtonClicked;

    publishEvent(analytics(subscriptionId, url));
    window?.open(url, '_blank');
  }, [type, subscriptionId]);

  const expireDate = useMemo(() => {
    const expirationDate = billing?.paymentMethod?.expirationDate;
    if (!expirationDate) return null;

    const isExpired = getExpirationStatus(expirationDate);
    if (isExpired) {
      return (
        <Expired className="caption">
          {`${translate('payments.expired', 'Expired:')}`} {expirationDate}
        </Expired>
      );
    }

    return (
      <p className="caption">
        {`${translate('payments.expires', 'Expires:')}`} {expirationDate}
      </p>
    );
  }, [billing?.paymentMethod?.expirationDate, translate]);

  const CreditCard = useMemo(() => {
    return (
      <CardContainer>
        <div>
          <PaymentFlagImage
            data-testid={`cardImg${formatedPaymentFlag}`}
            src={Images[formatedPaymentFlag as keyof typeof Images]}
          />
          <p className="caption">
            {creditCardMask(billing?.paymentMethod?.lastFour)}
          </p>
          {expireDate}
        </div>
        <div id="manage">
          <p className="caption-small">{linkedText}</p>
          <Button
            appearance="ghost"
            onClick={handleButtonClickCreditCard}
          >
            <span className="caption">
              {`${translate('devices.manage', 'Manage')}`}
            </span>
          </Button>
        </div>
      </CardContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formatedPaymentFlag,
    billing?.paymentMethod?.lastFour,
    expireDate,
    linkedText,
    handleButtonClickCreditCard,
    translate
  ]);

  const PayPal = useMemo(() => {
    return (
      <CardContainer>
        <div>
          <PayPalFlagImage
            data-testid={`cardImg${formatedTypePaymentFlag}`}
            src={Images[formatedTypePaymentFlag as keyof typeof Images]}
          />
          <p className="caption">{billing?.paymentMethod?.email}</p>
        </div>
        <div />
        <div id="manage">
          <p className="caption-small">{linkedText}</p>
          <Button
            appearance="ghost"
            onClick={handleButtonClickCreditCard}
          >
            <span className="caption">
              {`${translate('devices.manage', 'Manage')}`}
            </span>
          </Button>
        </div>
      </CardContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formatedTypePaymentFlag,
    billing?.paymentMethod?.email,
    linkedText,
    handleButtonClickCreditCard,
    translate
  ]);

  const getPaymentType = useCallback(() => {
    if (billing?.paymentMethod?.type === 'CREDIT_CARD')
      return <CardContent>{CreditCard}</CardContent>;
    if (billing?.paymentMethod?.type === 'PAY_PAL')
      return <CardContent>{PayPal}</CardContent>;
    return null;
  }, [CreditCard, PayPal, billing?.paymentMethod?.type]);

  return (
    <PaymentContainerCard
      data-testid="PaymentContainerCard"
      appearance="outlined"
      content={getPaymentType()}
    />
  );
}
