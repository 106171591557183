const getExpirationStatus = (expirationDate: string) => {
  if (!expirationDate) {
    return null;
  }

  const [part1, part2] = expirationDate
    .split(/[-/]/)
    .map((part) => parseInt(part, 10));

  let expirationMonth;
  let expirationYear;

  // If part1 is greater than 12, it's a year
  if (part1 > 12) {
    expirationYear = part1;
    expirationMonth = part2;
  } else {
    expirationMonth = part1;
    expirationYear = part2;
  }

  const currentDay = new Date();
  const cardExpiryDate = new Date(expirationYear, expirationMonth, 0); // 0 as the day argument to get the last day of the month
  const hasExpired = currentDay > cardExpiryDate;

  return hasExpired;
};

export default getExpirationStatus;
