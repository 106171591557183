import amex from './amex.svg';
import discover from './discover.svg';
import mastercard from './mastercard.svg';
import paypal from './paypal.svg';
import visa from './visa.svg';

const Images = {
  amex,
  discover,
  mastercard,
  paypal,
  visa
};

export default Images;
