import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size5};

  @media (min-width: ${tokens.layout.smMax}) {
    gap: ${tokens.layout.size4};
  }
`;

export const HpStoreOrders = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: ${tokens.layout.size5} 0 ${tokens.layout.size5} 0;
  color: ${tokens.color.gray9};
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoOrders = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${tokens.layout.size1};
`;
